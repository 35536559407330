<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">My payments</h2>
                            <div v-if="paginate.payments.list.length == 0 && loader" class="profile__content mb-3 mt-1">
                                <Spinner size="medium" line-fg-color="#e91e63" />
                            </div>
                            <div v-else>
                                <div class="profile__content mb-3 mt-1">
                                    <p v-if="getFilteredPayments.length == 0 && getPaymentsPending && !loader" class="no-results">No payments</p>
                                </div>
                                <div id="top" v-if="getFilteredPayments.length !== 0 && getPaymentsPending && !loader" class="profile__content mt-1">
                                    <div>
                                    <paginate name="payments" :list="getFilteredPayments" tag="div" :per="6" class="paginate-list d-none d-lg-block">
                                        <table class="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Subscription type</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                                <th>Invoice</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="payment in paginated('payments')" :key="payment.id">
                                                    <td v-if="payment.type == 2">STANDARD</td>
                                                    <td v-else-if="payment.type == 3">PREMIUM</td>
                                                    <td v-else-if="payment.type == 4">ULTIMATE</td>
                                                    <td>${{ payment.amount }}</td>
                                                    <td>{{ payment.date }}</td>
                                                    <td><a :href="payment.invoice" class="pdf-link">Download</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </paginate>
                                    <paginate-links @change="scrollToTop('top')" v-if="getFilteredPayments.length != 0" for="payments" :limit="3" :show-step-links="true" class="d-none d-lg-flex mt-4" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                                    </div>
                                    <paginate name="payments" :list="getFilteredPayments" tag="div" :per="6" class="paginate-list d-lg-none">
                                        <div v-for="payment in paginated('payments')" :key="payment.id" class="profile__payments">
                                            <p class="profile__payments-element">
                                                <strong>Subscription type:</strong>
                                                <span v-if="payment.type == 2">STANDARD</span>
                                                <span v-else-if="payment.type == 3">PREMIUM</span>
                                                <span v-else-if="payment.type == 4">ULTIMATE</span>
                                                <span v-else>NONE</span>
                                            </p>
                                            <p class="profile__payments-element">
                                                <strong>Amount:</strong> ${{ payment.amount }}
                                            </p>
                                            <p class="profile__payments-element">
                                                <strong>Date:</strong> {{ payment.date }}
                                            </p>
                                            <p class="profile__payments-element">
                                                <strong>Invoice:</strong>
                                                <a :href="payment.invoice" class="pdf-link">Download</a>
                                            </p>
                                        </div>
                                    </paginate>
                                    <paginate-links @change="scrollToTop('top')" v-if="getFilteredPayments.length != 0" for="payments" :limit="3" :show-step-links="true" class="d-lg-none mt-4" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                                </div>
                                <p v-if="getFilteredPayments.length != 0 && getPaymentsPending && !loader" class="faq__text mt-3 pr-4 pl-4 text-right"><simple-line-icons icon="info" size="small" color="#504e70" /> For more detailed invoice contact us directly: <a href="mailto:contactbrandingpavilion@gmail.com" class="form-link form-link--medium">contactbrandingpavilion@gmail.com</a></p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import Spinner from 'vue-simple-spinner'
import { mapGetters } from 'vuex'
import moment from 'moment'
import MoveTo from 'moveto'
export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    Spinner,
  },
  data: function () {
    return {
      loader: true,
      paginate: ['payments']
    }
  },
  methods: {
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    }
  },
  computed: {
    ...mapGetters([
      'getPaymentsPending',
      'getUserPayments'
    ]),
    getFilteredPayments () {
      if (this.getUserPayments) {
        var sorted = this.getUserPayments.map(function (payment) {
          return {
            id: payment.id,
            amount: payment.amount,
            date: moment.unix(payment.date).format('DD/MM/YYYY HH:mm'),
            number: payment.number,
            type: payment.type,
            invoice: payment.invoice
          }
        })

        return sorted.reverse()
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('getUserPayments')
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
